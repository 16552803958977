import React, { lazy } from "react";
import Loadable from "./Loadable";
import { Navigate } from "react-router";
import MobileDeveloping from "../pages/PageDeveloping/MobileDeveloping";

/* ***Layouts**** */
const GlobalArgumentapp = Loadable(
  lazy(() => import("../layout/GlobalArgumentapp"))
);
const AuthLayout = Loadable(lazy(() => import("../layout/AuthLayout")));
const BlankLayout = Loadable(lazy(() => import("../layout/BlankLayout")));
const UnidadesCompartidas = Loadable(
  lazy(() => import("../layout/UnidadesCompartidasLayout"))
);
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import("../pages/Page404/Notfound")));
const CerrarSesion = Loadable(
  lazy(() => import("../pages/error/CerrarSesion"))
);

/* ****Pages***** */
const Home = Loadable(lazy(() => import("../pages/PagPrincipal/Home")));
const MainCursos = Loadable(lazy(() => import("../pages/Cursos/MainCursos")));
const MainRecursos = Loadable(lazy(() => import("../pages/Recursos")));
const MainRecursosUnidadesArgumentapp = Loadable(
  lazy(() => import("../pages/Recursos/UnidadesArgumentapp"))
);
const MainPedagogicos = Loadable(
  lazy(() => import("../pages/Recursos/Reflexion"))
);
const MainAdminUsuario = Loadable(
  lazy(() => import("../pages/Login/Registrar/MainAdminUsuario"))
);
const MainModuloAdminGeneral = Loadable(
  lazy(() => import("../pages/ModuloAdminGeneral/MainModuloAdminGeneral"))
);
const MainDiseñoDiapositivas = Loadable(
  lazy(() =>
    import(
      "../pages/ModuloAdminGeneral/DiseñoDiapositivas/MainDiseñoDiapositivas"
    )
  )
);
const MainCursoUnitario = Loadable(
  lazy(() => import("../pages/VistaUnitariaClase/MainClaseVistaUnitaria"))
);
const MainVistaPrevia = Loadable(
  lazy(() => import("../pages/VistaUnitariaClase/VistaPrevia/MainVistaPrevia"))
);
const MainActividadesOnline = Loadable(
  lazy(() => import("../pages/ActividadesOnline/MainActividadesOnline"))
);
const MainVistaUnitariaActOn = Loadable(
  lazy(() =>
    import(
      "../pages/ActividadesOnline/VistaUnitariaActividadOnline/MainVistaUnitariaActOn"
    )
  )
);
const MainEspacio = Loadable(lazy(() => import("../pages/EspacioEstudiante")));
const MainVistaClasesActOn = Loadable(
  lazy(() =>
    import(
      "../pages/ActividadesOnline/VistaUnitariaActividadOnline/Contenido/Clases/MainVistaClasesActOn"
    )
  )
);
const MainRetroalimentacion = Loadable(
  lazy(() =>
    import(
      "../pages/ActividadesOnline/VistaUnitariaActividadOnline/retroalimentacion/MainRetroalimentacion"
    )
  )
);
const MainConfiguracion = Loadable(
  lazy(() => import("../pages/Configuracion"))
);
const MainContenidoArgumentapp = Loadable(
  lazy(() => import("../pages/UnidadesArgumentapp/MainContenidoArgumentapp"))
);
const MainVistaUnidadesArgumentapp = Loadable(
  lazy(() =>
    import(
      "../pages/UnidadesArgumentapp/VistaContenidoArgumentapp/MainVistaContenidoArgumentapp"
    )
  )
);
const MainCalendario = Loadable(lazy(() => import("../pages/Calendario")));
const Login = Loadable(lazy(() => import("../pages/Login")));
const ActividadTerminada = Loadable(
  lazy(() => import("../pages/Funcionalidades/ActividadTerminada"))
);
const MainVistaPizarraAct = Loadable(
  lazy(() => import("../pages/ActividadesOnline/Pizarra/MainVistaPizarraAct"))
);
const ImportPPTMain = Loadable(
  lazy(() =>
    import("../pages/Diapositivas/ImportarDiapostivasPPT/ImportPPTMain")
  )
);

//////////////////////////////////////

const MainUnidadesCompartidas = Loadable(
  lazy(() =>
    import(
      "../pages/Cursos/UnidadesCompartidas/VistaUnidadCompartida/MainListarClasesUnidCompartida"
    )
  )
);

const MainVistaClaseCompartida = Loadable(
  lazy(() =>
    import(
      "../pages/Cursos/UnidadesCompartidas/VistaUnidadCompartida/VistaClaseCompartida"
    )
  )
);

//////////////////////////////////////

/* ****Routes***** */

const Router = [
  {
    path: "/",
    element: <GlobalArgumentapp />,
    children: [
      { path: "", exact: true, element: <Home /> },
      { path: "home", exact: true, element: <Home /> },
      { path: "planificaciones", exact: true, element: <MainCursos /> },
      { path: "recursos", exact: true, element: <MainRecursos /> },
      {
        path: "materialargumentapp",
        exact: true,
        element: <MainRecursosUnidadesArgumentapp />,
      },
      { path: "pedagogicos", exact: true, element: <MainPedagogicos /> },
      {
        path: "administrarusuarios",
        exact: true,
        element: <MainAdminUsuario />,
      },
      {
        path: "moduloadministrativo",
        exact: true,
        element: <MainModuloAdminGeneral />,
      },
      {
        path: "disenodiapositivas",
        exact: true,
        element: <MainDiseñoDiapositivas />,
      },
      { path: "vistaclases", exact: true, element: <MainCursoUnitario /> },
      {
        path: "vistaclases/vistaprevia",
        exact: true,
        element: <MainVistaPrevia />,
      },
      {
        path: "actividadesOnline",
        exact: true,
        element: <MainActividadesOnline />,
      },
      {
        path: "actividad/:codigoAct",
        exact: true,
        element: <MainVistaUnitariaActOn />,
      },
      {
        path: "actividad/:codigoAct/espacioEstudiante",
        exact: true,
        element: <MainEspacio />,
      },
      {
        path: "actividad/:codigoAct/clase/:idClase",
        exact: true,
        element: <MainVistaClasesActOn />,
      },
      {
        path: "actividad/:codigo/clase/:idClase/feedback",
        exact: true,
        element: <MainRetroalimentacion />,
      },
      { path: "configuracion", exact: true, element: <MainConfiguracion /> },
      {
        path: "contenidoargumentapp",
        exact: true,
        element: <MainContenidoArgumentapp />,
      },
      {
        path: "clasecontenidoargumentapp",
        exact: true,
        element: <MainVistaUnidadesArgumentapp />,
      },
      {
        path: "clasecontenidoargumentapp/vistaprevia",
        exact: true,
        element: <MainVistaPrevia />,
      },
      { path: "calendario", exact: true, element: <MainCalendario /> },
      {
        path: "vistaclases/importardiapositivas/:data",
        exact: true,
        element: <ImportPPTMain />,
      },
      { path: "*", element: <Navigate to="/404" /> },
      { path: "404", element: <Error /> },
    ],
  },
  {
    path: "/404",
    element: <BlankLayout />,
    children: [
      { path: "", exact: true, element: <Error /> },
      { path: "cerrarsesion", element: <CerrarSesion /> },
    ],
  },
  {
    path: "/login",
    element: <AuthLayout />,
    children: [
      { path: "", exact: true, element: <Login /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/actividadesOnline/pizarra/termino",
    element: <BlankLayout />,
    children: [
      { path: "", exact: true, element: <ActividadTerminada /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/actividad/:codigoAct/clase/:idClase/pizarra",
    element: <BlankLayout />,
    children: [
      { path: "", exact: true, element: <MainVistaPizarraAct /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "planificaciones/unidadescompartidas",
    element: <UnidadesCompartidas />,
    exact: true,
    children: [
      { path: ":data", exact: true, element: <MainUnidadesCompartidas /> },
      {
        path: ":data/:idClase",
        exact: true,
        element: <MainVistaClaseCompartida />,
      },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/developing",
    element: <BlankLayout />,
    children: [
      { path: "mobile-developing", exact: true, element: <MobileDeveloping /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default Router;
