import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./redux/userSlice";
import vistaReducer from "./redux/vistaSelectorCreateDiapoSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    vista: vistaReducer,
  },
});
