import React from "react";
import { Box, Typography, Button } from "@mui/material";
import LogoNuevo from "../../assets/images/LogoNuevo.png";
function MobileDeveloping() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        p: 1,
      }}
    >
      <Box
        p={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          flex: 1,
          borderRadius: "24px",
          backgroundColor: "white",
          width: "100%",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={3}
          alignItems="center"
          justifyContent="center"
        >
          <img
            src={LogoNuevo}
            alt="logo"
            style={{
              width: "130px",
              height: "auto",
            }}
          />
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography
              variant="h5"
              align="center"
              sx={{
                fontSize: 24,
              }}
            >
              Sitio de Docente de Argumentapp en desarrollo para dispositivos móviles 🚧
            </Typography>{" "}
            <Typography
              variant="h5"
              align="center"
              display="flex"
              flexDirection="row"
              flexWrap={"wrap"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={0.5}
              sx={{
                fontSize: 18,
                color: "gray",
                padding: 0,
                margin: 0,
              }}
            >
              Disponible en versiones de {" "}
              <p
                style={{
                  color: "#9c27b0",
                  fontWeight: "bold",
                  padding: 0,
                  margin: 0,
                }}
              >
                escritorio
              </p>{" "}
              y{" "}
              <p
                style={{
                  color: "#9c27b0",
                  fontWeight: "bold",
                  padding: 0,
                  margin: 0,
                }}
              >
                tablet
              </p>{" "}
              🖥️
            </Typography>
          </Box>
          <Button
            variant="contained"
            onClick={() => {
              window.location.href = "https://www.argumentapp.app/";
            }}
          >
            Salir
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default MobileDeveloping;
