import { createSlice } from "@reduxjs/toolkit";

const vistaSelectorCreateDiapoSlice = createSlice({
  name: "vista",
  initialState: {
    vistaSelectorCrearDiapo: "plantillas", // Establece el estado inicial
    vistaSelectorCrearDiapoAcciones: "plantillas", // Establece el estado inicial
  },
  reducers: {
    setVistaSelectorCrearDiapo: (state, action) => {
      state.vistaSelectorCrearDiapo = action.payload;
    },
    setVistaSelectorCrearDiapoAcciones: (state, action) => {
      state.vistaSelectorCrearDiapoAcciones = action.payload;
    },
  },
});

export const { setVistaSelectorCrearDiapo, setVistaSelectorCrearDiapoAcciones } =
  vistaSelectorCreateDiapoSlice.actions;
export default vistaSelectorCreateDiapoSlice.reducer;
