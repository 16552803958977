import React from 'react';
import './spinner.css';
import PropTypes from 'prop-types';

/**
 * The Spinner function is a React component that displays a loading spinner with customizable height.
 */
const Spinner = ({ fullPage = "none" }) => (
  <div style={{ height: fullPage === false ? '100vh' : fullPage }} className="fallback-spinner">
    <div className="loading component-loader">
      <div className="effect-1 effects" />
      <div className="effect-2 effects" />
      <div className="effect-3 effects" />
    </div>
  </div>
);

export default Spinner;

Spinner.propTypes = {
  fullPage: PropTypes.string,
};